const ehelseUrl = 'https://www.ehelse.no'
const ehelseKodeverk = ehelseUrl + '/kodeverk-og-terminologi'
const helsedirUrl = 'https://www.helsedirektoratet.no'
const helsedirDigitaliseringOgEhelse =
	helsedirUrl + '/digitalisering-og-e-helse'
const helsedirKodeverk =
	helsedirDigitaliseringOgEhelse + '/helsefaglige-kodeverk'

const aboutIcd = '/icd'
const aboutNkpk = '/nkpk'
const aboutPhbu = '/phbu'
const aboutIcpc = '/icpc'
const aboutKis = '/kodeverk-i-e-helsestandarder'
const aboutNorpat = '/norpat'
const aboutLab = '/nlk'

const aboutSnomedct = '/SNOMED-CT'

const reportErrorLab = 'lab@helsedir.no'
const reportErrorEmail = 'kodehjelp@helsedir.no'
const reportErrorAdm = 'kodehjelp@helsedir.no'
const koteUrl = 'https://kote.helsedirektoratet.no'
const terminologiString = '/terminologi'
const kliniskeString = '/kliniske'

const snomedctBrowser = 'https://snowstorm.terminologi.helsedirektoratet.no'

const blueBookIcdAttachment =
	'/standarder/icd-10-psykiske-lidelser-og-atferdsforstyrrelse-kliniske-beskrivelser-og-diagnostiske-retningslinjer-blaboka/ICD-10%20Psykiske%20lidelser%20og%20atferdsforstyrrelse%20kliniske%20beskrivelser%20og%20diagnostiske%20retningslinjer.pdf/_/attachment/inline/57e72124-345b-47a8-aacc-2d572c8f9f78:6875a2309af7447a26d9731a6e7ff2cba814c14e/ICD-10%20Psykiske%20lidelser%20og%20atferdsforstyrrelse%20kliniske%20beskrivelser%20og%20diagnostiske%20retningslinjer.pdf'
const codingGuideIcdAndNkpk2025Attachment =
	'https://www.helsedirektoratet.no/veiledere/kodeveiledning-2025-regler-og-veiledning-for-klinisk-koding-i-spesialisthelsetjenesten'
const codingGuidePhbuAttachment =
	'https://www.helsedirektoratet.no/veiledere/multiaksial-klassifikasjon-i-psykisk-helsevern-for-barn-og-unge-phbu-veiledning-til-koding'

/* Temporary download files until the api works well with clinical codesystems */
const downloadExcelIcdTemp =
	'/icd/historiske-filer/ICD-10%202025%20kodeliste.xlsx'
const downloadExcelIcpcTemp =
	'/icpc/historiske-filer/Fil%201%202025%20-%20ICPC-2%20koderegister%20med%20utvidet%20termsett%20(flere%20linjer%20per%20kode).xlsx'
const downloadExcelNkpkTemp =
	'/nkpk/historiske-filer/Alle%20prosedyrekoder%20NKPK%202025%20-%20Oppdatert%202.%20desember%202024.xlsx'
const downloadExcelPhbuTemp =
	'/phbu/historiske-filer/Kodeliste%20PHBU%202025.xlsx'
const downloadExcelNorpatTemp =
	'/norpat/historiske-filer/Norsk%20patologikodeverk%20(NORPAT)%202025.xlsx'

const userManualFile = 'Brukerveiledning_FinnKode.pdf'

const externalUrls = {
	helsedirUrl,
	helsedirDigitaliseringOgEhelse,

	/* user manual */
	userManual: userManualFile,

	/* about */
	aboutLab: helsedirKodeverk + aboutLab,
	aboutNorpat: helsedirKodeverk + aboutNorpat,
	aboutIcd: helsedirKodeverk + aboutIcd,
	aboutIcpc: helsedirKodeverk + aboutIcpc,
	aboutPhbu: helsedirKodeverk + aboutPhbu,
	aboutNkpk: helsedirKodeverk + aboutNkpk,
	aboutKis: helsedirKodeverk + aboutKis,
	aboutSnomedct: ehelseKodeverk + aboutSnomedct,

	/* report error */
	reportErrorClinical: koteUrl + kliniskeString,
	reportErrorTerminology: koteUrl + terminologiString,
	reportErrorLab,
	reportErrorEmail,
	reportErrorAdm,

	/* temp download file urls */
	downloadExcelIcdTemp: helsedirKodeverk + downloadExcelIcdTemp,
	downloadExcelIcpcTemp: helsedirKodeverk + downloadExcelIcpcTemp,
	downloadExcelNkpkTemp: helsedirKodeverk + downloadExcelNkpkTemp,
	downloadExcelPhbuTemp: helsedirKodeverk + downloadExcelPhbuTemp,
	downloadExcelNorpatTemp: helsedirKodeverk + downloadExcelNorpatTemp,

	/* blåbok og kodeveilder */
	blueBookIcd: helsedirKodeverk + blueBookIcdAttachment,
	codingGuideIcd: codingGuideIcdAndNkpk2025Attachment,
	codingGuideNkpk: codingGuideIcdAndNkpk2025Attachment,
	codingGuidePhbu: codingGuidePhbuAttachment,

	snomedctBrowser,
}

export default externalUrls
