import { Paging, withSearch } from '@elastic/react-search-ui'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { Button, Loading } from 'components/common'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { paths, scrollToTop } from 'utils'
import ResultView from '../SnomedResultView'
import { useStoreContext } from 'context/StoreProvider'
import { useTranslation } from 'react-i18next'
import { ScrollWrapper, SidebarWrapper } from 'components/search/buckets/common'
import { isMobile } from 'react-device-detect'
import { MetaInfoBar, SnomedFilter } from './common'
import { SidebarContent } from 'components/search/buckets/SearchWithFilter/common/SearchResultLayout/style'

interface BodyResultsProps {
	searchTerm: string
	totalResults: number
	resultSearchTerm: string
	isFetching: boolean
	isLoading: boolean
}

const SnomedBodyResults = ({
	searchTerm,
	resultSearchTerm,
	isLoading,
}: BodyResultsProps) => {
	const navigate = useNavigate()

	const { state, actions } = useStoreContext()
	const [showFilter, setShowFilter] = useState<boolean>(
		isMobile || window.innerWidth < 992 ? false : true
	)
	const { t } = useTranslation()

	const { code, inputRef } = state

	const { updateCodeValue } = actions

	/* This checks if the searchTerm changes to something different than the first searchTerm, 
  so that the content updates with the result if the user searches for something else  */
	useEffect(() => {
		if (searchTerm !== resultSearchTerm && code) {
			navigate(`${paths.snomed}`)
			updateCodeValue('')
		}
	}, [searchTerm, resultSearchTerm, code, navigate, updateCodeValue])

	/* This makes sure the input gets refocused after the searchTerm state update */
	useEffect(() => {
		if (inputRef?.current) {
			inputRef.current.focus()
		}
	}, [searchTerm])

	// const headerHeight = document.getElementById('site-header')?.clientHeight || 0
	// const searchWrapperHeight =
	// 	document.getElementById('snomed-search-wrapper')?.clientHeight || 0
	// const tabViewHeight =
	// 	document.getElementById(`tab-view-snomed`)?.clientHeight || 0

	// const calculateHeight = headerHeight + searchWrapperHeight + tabViewHeight + 3
	return (
		<>
			<SidebarContent $size='md'>
				<Row className='row-lg-cols-2 gap-3 gap-lg-0'>
					<Button
						variant='tertiary'
						icon={!showFilter ? faArrowDown : faArrowUp}
						className='justify-content-between d-flex d-lg-none'
						size='sm'
						iconright
						color='grey'
						onClick={() => setShowFilter(!showFilter)}
						textColor='black'
					>
						{!showFilter
							? t('nlkPage.filter.showFilter')
							: t('nlkPage.filter.hideFilter')}
					</Button>
					{showFilter && (
						<SidebarWrapper className='p-0'>
							<SnomedFilter />
						</SidebarWrapper>
					)}
					<Col className='p-0' lg={9}>
						{isLoading && <Loading />}
						{/* Fordi her skal ikke søkeresultatene vises før søk - reversert av DEHPKTSD-237 */}
						{
							/*resultSearchTerm !== '' &&*/ !isLoading && (
								<>
									<MetaInfoBar />
									<ResultView />
									<Row onClick={scrollToTop} className='py-2'>
										<Paging />
									</Row>
								</>
							)
						}
					</Col>
				</Row>
			</SidebarContent>
		</>
	)
}

export default withSearch(({ searchTerm, resultSearchTerm, isLoading }) => ({
	searchTerm,
	resultSearchTerm,
	isLoading,
}))(SnomedBodyResults)
