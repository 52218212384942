import { Col, Image } from 'react-bootstrap'
import { Heading, Link, Wrapper } from 'components/common'
import { FooterWrapper } from './style'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { externalUrls } from 'utils'
import { useTranslation } from 'react-i18next'

const Footer = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	return (
		<>
			<FooterWrapper>
				<Wrapper
					fluid={false}
					className={`d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-end gap-4 gap-lg-0 `}
				>
					<Col xs={12} lg={4}>
						<Heading variant='h3' className='mb-2' id='footer-heading'>
							{t('footer.banner.title')}
						</Heading>
						<p className='mb-0' id='footer-description'>
							{t('footer.banner.text')}
						</p>
					</Col>

					<Col
						as={'section'}
						xs={12}
						md={6}
						lg={4}
						className='footer-text mt-md-2 mt-3 p-0 d-flex flex-column gap-3'
					>
						<Link
							icon={faChevronRight}
							iconRight
							href={externalUrls.helsedirDigitaliseringOgEhelse}
							text={t('footer.links.sectionDigitaliseringOgEhelse')}
							target='_blank'
							fontSize='18px'
							isBlue={false}
						/>

						<Link
							icon={faChevronRight}
							iconRight
							href={`mailto:${externalUrls.reportErrorEmail}`}
							target='_blank'
							text={`Gi oss tilbakemelding (${externalUrls.reportErrorEmail})`}
							fontSize='18px'
							isBlue={false}
						/>
						<Link
							icon={faChevronRight}
							iconRight
							href={externalUrls.userManual}
							target='_blank'
							text={t('footer.links.userManual')}
							fontSize='18px'
							isBlue={false}
							isBold={true}
						/>
					</Col>
					<Col
						as={'section'}
						xs={12}
						md='auto'
						className='p-0 m-0 d-flex align-items-end align-self-center'
					>
						<a
							href={externalUrls.helsedirUrl}
							target='_blank'
							rel='noopener noreferrer'
						>
							<Image
								src='/assets/hdir-logo-neg.svg'
								height='48px'
								alt='Logo for e-helse'
							/>
						</a>
					</Col>
				</Wrapper>
			</FooterWrapper>
		</>
	)
}

export default Footer
