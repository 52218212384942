import type { FacetViewProps } from '@elastic/react-search-ui-views'
import { appendClassName } from 'utils'
import { useEffect, useState } from 'react'
import { Checkbox, FilterWrapper } from 'components/common/elastic/style'

// This component is used to include codes whose length is 5 (without considering punctuation)
// This can very likely be done in a much better manner if we used integer datatypes in
// elasticsearch and custom filtering without the enterprise search API.
const SingleValueFacet = ({
	className,
	label,
	options,
	onRemove,
	onSelect,
	values,
}: FacetViewProps) => {
	const trueOptions = options.find((option) => option.value === '5')
	const isSelected = values.includes('5')
	console.log('options', options)
	console.log('values', values)
	const [isDisabled, setIsDisabled] = useState<boolean>(false)

	// If no selections, initialize with all options selected except '5'
	useEffect(() => {
		if (options.every((option) => option.selected === false)) {
			onSelect(['1', '2', '3', '4', '6', '7', '8', '9', '10'])
		}
	}, [])

	const handleToggle = () => {
		if (isSelected) {
			// Documents with code_length 5 should be hidden
			onRemove('5')
		} else {
			// Documents with all code_length values should be shown
			onSelect(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'])
		}
	}

	useEffect(() => {
		if (!trueOptions) {
			setIsDisabled(true)
		} else {
			setIsDisabled(false)
		}
	}, [trueOptions, isDisabled])

	return (
		<FilterWrapper className={appendClassName(className)}>
			<Checkbox
				type='checkbox'
				label={label}
				id={`facet-${label}`}
				name={`facet-${label}`}
				disabled={isDisabled}
				checked={isSelected}
				onChange={handleToggle}
				style={{ fontWeight: isSelected ? 'bold' : 'normal' }}
			/>
		</FilterWrapper>
	)
}

export default SingleValueFacet
